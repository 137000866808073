<template>
  <div class="container-page">
    <p class="title-inner">Данные о мировых ценах</p>
    <div class="mb-16">
      <ButtonStock class="mr-2" :disabled="importInProgress" title="Создать" @click="editCard(0)" />
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Год</th>
              <th>Месяц</th>
              <th>Товар</th>
              <th>Стоимость</th>
              <th>Единица измерения</th>
              <th>Место торгов/биржа</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  placeholder="Год"
                  v-model="filter.year"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  :select2Options="months"
                  placeholder="Выберите"
                  type="select2"
                  v-model="filter.month"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  :select2Options="parameterList"
                  placeholder="Выберите"
                  type="select2"
                  v-model="filter.parameterId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="От"
                  v-model="filter.costFrom"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  placeholder="До"
                  v-model="filter.costTo"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput placeholder="" v-model="filter.unit" v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter">
                <FilterInput placeholder="" v-model="filter.marketplace" v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter"></th>
              <th class="filter"></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.year }}</td>
              <td>{{ item.monthName }}</td>
              <td>{{ item.parameterName }}</td>
              <td>{{ formatNumber(item.cost, 1, ' ', ',') }}</td>
              <td>{{ item.unit }}</td>
              <td>{{ item.marketplace }}</td>
              <td>
                <IconComponent :disabled="importInProgress" @click="editCard(item.id)" name="mode" />
              </td>
              <td>
                <IconComponent :disabled="importInProgress" @click="deleteCard(item.id)" name="delete" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="9">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="9">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <a href="javascript:" @click="getTemplate">Скачать шаблон</a>
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import textMixin from '@/common/mixins/text';
  import Utils from '@/common/utils';

  import ImportModal from '@/components/modals/ImportModal';

  import countryApi from '@/modules/nsi/api/country';

  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/worldprice';

  export default {
    name: 'Worldprice',
    components: { ImportModal, ModalComponent, ButtonStock, FilterInput, DefaultFilter, IconComponent },
    mixins: [filtermanager, textMixin],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        importInProgress: false,
        importModal: false,
        isLoading: false,

        select2Settings: Constants.select2Settings,
        countries: [],
        parameterList: [],
        filter: {},
        months: Constants.monthList,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage();
      });
    },
    methods: {
      loadDictionaries() {
        countryApi.search().then((response) => {
          this.countries = [
            { text: 'Все страны', id: -1 },
            ...response.data.items.map((x) => ({ id: x.id, text: x.name })),
          ];
        });
        Utils.searchNsi('NsiWorldpriceParameter', this.defaultRequest)
          .then((response) => {
            this.parameterList = response.data;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      loadPage(append) {
        this.isLoading = !append;
        var params = Object.assign(this.filter, this.request);
        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.uploadFile(file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      closeModal() {
        this.importModal = false;
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      editCard(id) {
        this.$router.push({ name: 'WorldpriceCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      getTemplate() {
        Api.getTemplate();
      },
    },
  };
</script>

<style scoped></style>
